const styles = (theme) => ({
    head:{
        fontSize: 20,
        // marginBottom: 20,
        fontFamily:"Roboto",
        fontWeight:"400", 
        display:"flex",
        justifyContent:"space-between",
        [theme.breakpoints.up("xl")]:{
            fontSize: 30,
         },
    },
    divide:{
        marginTop: "10px !important",
        marginBottom:"30px !important"
    },
    permission: {
        fontSize: 13,
        
        fontFamily:"Roboto",
        fontWeight:"400", 
        marginBottom:5,
        [theme.breakpoints.up("xl")]:{
            fontSize: 18,
         },
    },
    button: {
        display: 'flex',
             
    },
    update:{
        marginLeft: 10
    },
    logo: {
        textAlign: 'center',

    },
    text1: {
        textAlign: 'center',
        padding: "5%",
        fontSize: 16,
        fontFamily:"Roboto",
        fontWeight:"400", 
        color:"#36333D"
    },
    button1: {
        textAlign: 'center'
    },
    warning:{
        textAlign: 'center',
        fontSize: 20,
        color:"#36333D",
        
        fontFamily:"Roboto",
        fontWeight:"600", 
        [theme.breakpoints.up('xl')]: {
            fontSize: 35,
        }
    },
    text:{
        textAlign: 'center',
        fontSize: 14,
        padding:"5%",
        fontFamily:"Roboto",
        fontWeight:"600",
        color:"#36333D",
        [theme.breakpoints.up('xl')]: {
            fontSize: 20,
        }
    },
    button2:{
        display:'flex',
        justifyContent: 'center'
    },
    logo1: {
        textAlign: 'center',
        padding: 10       
    },
    ok: {
        marginLeft: 10
    },
    colar: {
        backgroundColor: "#A2AAAD !important"
    },
    main:{
        display:"flex",
        justify: "space-between",
        alignItems: "center",
        marginBottom: 20,
    },
    main: {
        display:"flex",
        justifyContent:"space-between",
        alignItems: "center",
        textAlign:"end"
       
     },
    button3: {
        
        justifyContent:"end",
        textAlign: "end"
    },
    text3:{
        fontSize: 20,
        fontFamily:"Roboto",
        fontWeight:"400",
        [theme.breakpoints.up("xl")]:{
            fontSize: 30,
        },
    },
    select: {
        marginLeft: 10,
        fontSize: 20,
        fontFamily:"Roboto",
        fontWeight:"400",
    },
    menuitem: {
        fontSize: 20,
        fontFamily:"Roboto",
        fontWeight:"400",
    },
})

export default styles