import axios from "axios";

export const api_settings = {
    url_base: process.env.REACT_APP_API_URL
};

export const api_endpoints = {
    companyList: "account/company-list/",
    usercompanyList: "account/user-company-list/",
    countryList: "account/country-list/",
    login: 'account/login/',
    verifyRoot: 'account/root-access-list/',
    otpToAccessRoot: 'account/root-user-access/',
    register: 'account/register/',
    comp_register: 'account/register-company/',
    forgot: 'account/request-reset-email/',
    reset: 'account/password-reset-complete/',
    activation: 'account/verify-email/',
    permissionRequest: 'account/request-access/',
    permission_access: 'account/verify-permission/',
    comp_activation: 'account/verify-company/',
    root_access_activation: 'account/verify-root-permission/',
    workspaceUsers: 'workspace/user/',
    allworkspaceUsers: 'workspace/allworkspaceuser/',
    xdataBuckets: 'xdata/',
    xdataBucket: 'xdata/bucket',
    locations: "cc-api/location-analytics/",
    analytics: 'analytics/',
    admin:'account/',
    legacyDatabase: 'db/',
    legacyAnalytics: 'history/',
    legacyDbDetails: 'db/version/',
    legacyDownloadLog: 'downloadlog/db/dl/dlogs',
    legacyDbDownload: 'db/version-download/',
    themes: 'themes/',
    themesDetails: 'themes/theme-version/',
    createTheme: 'themes/theme-version/',
    downloadTheme: 'themes/dl/by-version/',
    xdataUpload: 'xdata/upload/',
    xdataDownload: 'xdata/download/',
    xdataDownloadLogs: 'downloadlog/xdata/dlogs',
    xdataVersionDelete: 'xdata/version/',
    impersonate: 'impersonate/',
    cc_bucketAnalytics_AnnualSalesTrend: 'cc-api/bucket-analytics/annual-sales-trend/',
    cc_bucketAnalytics_AnnualSalesVolume: 'cc-api/bucket-analytics/sales-volume/',
    cc_bucketAnalytics_TopTenColors: 'cc-api/bucket-analytics/top-ten-colors/',
    cc_bucketAnalytics_TopTenProducts: 'cc-api/bucket-analytics/top-product/',
    c_bucketAnalytics_TopTenSubProducts: 'cc-api/bucket-analytics/top-subproduct/',
    c_bucketAnalytics_TopTenBaseProducts: 'cc-api/bucket-analytics/top-base/',
    cc_bucketAnalytics_TopCanSales: 'cc-api/bucket-analytics/top-can-sales/',
    cc_bucketAnalytics_CustomFormulaSales: 'cc-api/bucket-analytics/custom-formula-sales/',
    cc_bucketAnalytics_MachineStat: 'cc-api/bucket-analytics/machine-stat/',
    cc_bucketAnalytics: 'cc-api/bucket-analytics/',
    cc_bucketAnalytics_NewShop: 'cc-api/bucket-analytics/new-shops/',
    cc_bucketAnalytics_VolumeTinted: 'cc-api/bucket-analytics/volume-tinted/',
    cc_bucketAnalytics_Location: 'cc-api/bucket-analytics/location/',
    cc_bucketAnalytics_locationBasedSale: 'cc-api/bucket-analytics/location-based-sales/',
    cc_bucketAnalytics_colorant_consumption: 'cc-api/bucket-analytics/colorant-consumption/',
    cc_locationAnalytics_locationInformation: 'cc-api/location-analytics/location-information/',
    cc_locationAnalytics_volumeOrder: 'cc-api/location-analytics/volume-order-tinted/',
    cc_locationAnalytics_totalSales: 'cc-api/location-analytics/total-sales-volume/',
    cc_locationAnalytics_customFormula: 'cc-api/location-analytics/custom-formula-sales/',
    cc_locationAnalytics_TopTenColors: 'cc-api/location-analytics/top-ten-colors/',
    cc_locationAnalytics_TopProducts: 'cc-api/location-analytics/top-products/',
    cc_locationAnalytics_TopSubProducts: 'cc-api/location-analytics/top-subproducts/',
    cc_locationAnalytics_TopBases: 'cc-api/location-analytics/top-bases/',
    cc_locationAnalytics_ColorantConsumption: 'cc-api/location-analytics/colorant-consumption/',
    cc_locationAnalytics_AvgLocationFootfall: 'cc-api/location-analytics/average-location-footfall/',
    cc_locationAnalytics_MachineStat: 'cc-api/location-analytics/machine-stat/',
    cc_adminDashboard_CountriesNoOfMachines: 'cc-api/admin-dashboard/countries-with-numer-of-machines/',
    cc_adminDashboard_TopTenCountries: 'cc-api/admin-dashboard/top-ten-countries-with-high-machine-count/',
    cc_adminDashboard_TopTenCustomers: 'cc-api/admin-dashboard/customers-with-highest-machines/',
    cc_adminDashboard_CustomersList: 'cc-api/admin-dashboard/list-customers/',
    cc_adminDashboard_UserMachineStat: 'cc-api/admin-dashboard/user-machine-stat/',
    cc_adminDashboard_UserDataUsage: 'cc-api/admin-dashboard/list-customers/',
    cc_adminDashboard_ResetUserDataUsage: 'cc-api/admin-dashboard/reset-data-usage/',
    cc_bucketAnalytics_scheduled_report_and_mail: 'cc-api/bucket-analytics/scheduled_report_and_mail/',
    mandatory_update: 'xdata/',
    two_FA_update: 'xdata/otp_update/',
    userQuery: 'account/user_query_list/',
    deleteuserQuery: 'account/remove_user_query/',
    resetotpuserQuery: 'account/refresh_user_query_otp/',
    reopenuserQuery: 'account/reopen_user_query/',
    resolveduserQuery: 'account/requested-query-resolved/',
    rootAccessUsers: 'account/root_access_list/',
    actionrootAccessUsers: 'account/root_access/',
    serviceEngineers: 'service_engineers/',
    serviceEngineersUpload: 'service_engineers/upload/',
    sampleServiceEngineerTemplate: 'service_engineers/sample_service_engineer_template/',
};

const api = axios.create({
    baseURL: api_settings.url_base
});
api.defaults.headers = {
    "Content-Type": "application/json",
};

export default api;
