import React,{useState,useEffect} from 'react'
import { styled } from '@mui/system';
import { CustomDropDown } from 'elements';
import { ReactComponent as PeopleIcon} from "assets/icons/users.svg";
import { ReactComponent as DataIcon} from "assets/icons/data.svg";
import { ReactComponent as DatabaseIcon} from "assets/icons/database.svg";
import { ReactComponent as ThemesIcon} from "assets/icons/themes.svg";
import { RiUserSettingsLine } from "react-icons/ri";
import { useSelector } from "react-redux"
import {
  Box
} from '@mui/material';
import { ReactComponent as Logo } from "assets/images/corob-logo.svg";
import {useTranslation} from "react-i18next";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';

const SideBarRoot = styled('div')(({ theme }) => ({
  position:"fixed",
  left:0,
  height:"100%",
  overflow: 'auto',
  width:333,
  zIndex: 1200,
  backgroundColor:"#C8102E",
  padding:"15px 24px 60px 24px",
  '& .appLogo' : {
    width:190, 
    height:70
  },
  [theme.breakpoints.down('xl')]: {
    width:250,
    padding:"10px 19px 30px 19px",
    '& .appLogo' : {
      width:170, 
      height:50
    },
  }
}));

export default function SideBar() {
  const {t} = useTranslation('common');
  const { user } = useSelector((state) => state.auth.login);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  const [navlinks, setNavlinks] = useState([]);

  useEffect(() => {  
    const links = [];

    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data
      if(impersonateUserInfoData?.is_workspace_admin){
        links.push({
            name:"workspace-users",
            displayName:t('sidebar.WorkspaceUsers'),
            level:0,
            link:"/dashboard/workspace-users",
            icon:<PeopleIcon />,
            access:['all']
        })
      }
      if(impersonateUserInfoData?.is_colordb_editor || impersonateUserInfoData?.is_historians){
        const xdata_sub = []
        const legacy_sub = []
        if(impersonateUserInfoData?.is_colordb_editor){
            xdata_sub.push({
                name:"x-data-buckets",
                displayName:t('sidebar.Buckets'), 
                level:1,
                link:"/dashboard/xdata/buckets",
                parent:"x-data",
                access:['all'],
            })
            legacy_sub.push({
                name:"legacy-databases-databases",
                displayName:t('sidebar.Databases'), 
                level:1,
                link:"/dashboard/legacy/databases",
                parent:"legacy-databases",
                access:['all'],
            })
        }
        if(impersonateUserInfoData?.is_historians === true && impersonateUserInfoData?.is_workspace_graph === true){
          xdata_sub.push({
            name:"x-data-analytics",
            displayName:t('sidebar.Analytics'), 
            level:1,
            link:localStorage.getItem("menu") === "/dashboard/xdata/analytics" ? "/dashboard/xdata/analytics": localStorage.getItem("menu") === "/dashboard/xdata/analytics/locations/" ? "/dashboard/xdata/analytics/locations/" : "/dashboard/xdata/analytics",
            parent:"x-data",
            access:['all'],
            subSection: [
              {
                name:"xdata-analytics-buckets",
                displayName:t('sidebar.Buckets'), 
                level:2,
                link:"/dashboard/xdata/analytics",
                parent:"x-data-analytics",
                mainLink: "/dashboard/x-data",
                access:['all']
              },
              {
                name:"xdata-analytics-locations",
                displayName:t('sidebar.Locations'), 
                level:2,
                link:"/dashboard/xdata/analytics/locations/",
                parent:"x-data-analytics",
                mainLink: "/dashboard/x-data",
                access:['all']
              }
            ]
        })
            legacy_sub.push({
                name:"legacy-databases-analytics",
                displayName:t('sidebar.Analytics'), 
                level:1,
                link:"/dashboard/legacy/analytics",
                parent:"legacy-databases",
                access:['all']
            })
        }
        if(impersonateUserInfoData?.is_historians === true && impersonateUserInfoData?.is_workspace_graph === false){
          xdata_sub.push({
            name:"x-data-analytics",
            displayName:t('sidebar.Analytics'), 
            level:1,
            link:localStorage.getItem("menu") === "/dashboard/xdata/analytics" ? "/dashboard/xdata/analytics": localStorage.getItem("menu") === "/dashboard/xdata/analytics/locations/" ? "/dashboard/xdata/analytics/locations/" : "/dashboard/xdata/analytics",
            parent:"x-data",
            access:['all'],
            subSection: [
              {
                name:"xdata-analytics-buckets",
                displayName:t('sidebar.Buckets'), 
                level:2,
                link:"/dashboard/xdata/analytics",
                parent:"x-data-analytics",
                mainLink: "/dashboard/x-data",
                access:['all']
              }
            ]
        })
            legacy_sub.push({
                name:"legacy-databases-analytics",
                displayName:t('sidebar.Analytics'), 
                level:1,
                link:"/dashboard/legacy/analytics",
                parent:"legacy-databases",
                access:['all']
            })
        }
        links.push({
            name:"x-data",
            displayName:t('sidebar.XData'), 
            level:0,
            link:"/dashboard/x-data",
            icon:<DataIcon />,
            access:['all'],
            subSection: xdata_sub
        })

        links.push({
            name:"legacy-databases",
            displayName:t('sidebar.LegacyDatabases'),
            level:0,
            link:"/dashboard/legacy-databases",
            icon:<DatabaseIcon />,
            access:['all'],
            subSection: legacy_sub
        })
      }
      if(impersonateUserInfoData?.is_theme_editor){
        links.push({
            name:"themes",
            displayName:t('sidebar.Themes'),
            level:0,
            link:"/dashboard/themes",
            icon:<ThemesIcon />,
            access:['all']
        })
      }

      if(impersonateUserInfoData?.is_colordb_editor || impersonateUserInfoData?.is_historians ||
        impersonateUserInfoData?.is_theme_editor || impersonateUserInfoData?.is_workspace_graph ){
          links.push({
            name:"service-engineer",
            displayName:"Service Engineer",
            level:0,
            link:"/dashboard/service-engineer",
            icon:<EngineeringOutlinedIcon fontSize="50px"/>,
            access:['all']
          })

        links.push({
          name:"complaint-register",
          displayName:"Support",
          level:0,
          link:"/dashboard/complaint-registration",
          icon:<ChatBubbleOutlineOutlinedIcon />,
          access:['all']
        })
      }


    }else{
      if(user?.is_superuser){
          links.push({
              name:"admin",
              displayName:t('sidebar.Admin'),
              level:0,
              link:"/dashboard",
              icon:<RiUserSettingsLine />,
              access:['all'],
              subSection: [
                {
                name:"admin-dashboard",
                displayName:t('sidebar.Dashboard'), 
                level:1,
                link:"/dashboard/dashboard",
                parent:"admin",
                mainLink: "/dashboard/dashboard",
                access:['all']
              },
              {
                name:"admin-allusers",
                displayName:t('sidebar.AllUsers'), 
                level:1,
                link:"/dashboard/admin",
                parent:"admin",
                mainLink: "/dashboard/dashboard",
                access:['all']
              }
            ]
          })
          links.push({
              name:"django_admin",
              displayName:t('sidebar.DjangoAdmin'),
              level:0,
              link:""+process.env.REACT_APP_API_URL+"admin/",
              icon:<RiUserSettingsLine />,
              access:['all']
          })
      }

      // if(user?.is_superuser){
    if(user?.is_staff || user?.is_superuser){
        links.push({
            name:"asign-user-action",
            displayName:t('sidebar.AsignUserAction'),
            level:0,
            link:"/dashboard/asign-user-action",
            icon:<PeopleIcon />,
            access:['all']
        })
    }

    if((user?.is_supervisor && user?.is_superuser === false)){
      links.push({
          name:"asign-user-action",
          displayName:t('sidebar.RootPermissionUsers'),
          level:0,
          link:"/dashboard/root-access-list",
          icon:<PeopleIcon />,
          access:['all']
      })
    }

      if(user?.is_superuser || user?.is_workspace_admin){
          links.push({
              name:"workspace-users",
              displayName:t('sidebar.WorkspaceUsers'),
              level:0,
              link:"/dashboard/workspace-users",
              icon:<PeopleIcon />,
              access:['all']
          })
      }

      if(user?.is_superuser || user?.is_colordb_editor || user?.is_historians || user?.is_workspace_graph){
          const xdata_sub = []
          const legacy_sub = []
          if(user?.is_superuser || user?.is_colordb_editor){
              xdata_sub.push({
                  name:"x-data-buckets",
                  displayName:t('sidebar.Buckets'),
                  level:1,
                  link:"/dashboard/xdata/buckets",
                  parent:"x-data",
                  access:['all'],
              })
              legacy_sub.push({
                  name:"legacy-databases-databases",
                  displayName:t('sidebar.Databases'),
                  level:1,
                  link:"/dashboard/legacy/databases",
                  parent:"legacy-databases",
                  access:['all'],
              })
          }
          // if(user?.is_superuser || user?.is_historians)
          if(user?.is_superuser === false && user?.is_historians === true && user?.is_workspace_graph === false ){
              xdata_sub.push({
                  name:"x-data-analytics",
                  displayName:t('sidebar.Analytics'),
                  level:1,
                  link:localStorage.getItem("menu") === "/dashboard/xdata/analytics" ? "/dashboard/xdata/analytics": localStorage.getItem("menu") === "/dashboard/xdata/analytics/locations/" ? "/dashboard/xdata/analytics/locations/" : "/dashboard/xdata/analytics",
                  parent:"x-data",
                  access:['all'],
                  subSection: [
                    {
                      name:"xdata-analytics-buckets",
                      displayName:t('sidebar.Buckets'),
                      level:2,
                      link:"/dashboard/xdata/analytics",
                      parent:"x-data-analytics",
                      mainLink: "/dashboard/x-data",
                      access:['all']
                    }
                  ]
              })
              legacy_sub.push({
                  name:"legacy-databases-analytics",
                  displayName:t('sidebar.Analytics'),
                  level:1,
                  link:"/dashboard/legacy/analytics",
                  parent:"legacy-databases",
                  access:['all']
              })
          }
          if(user?.is_superuser || user?.is_historians === true && user?.is_workspace_graph === true){
            xdata_sub.push({
                name:"x-data-analytics",
                displayName:t('sidebar.Analytics'),
                level:1,
                link:localStorage.getItem("menu") === "/dashboard/xdata/analytics" ? "/dashboard/xdata/analytics": localStorage.getItem("menu") === "/dashboard/xdata/analytics/locations/" ? "/dashboard/xdata/analytics/locations/" : "/dashboard/xdata/analytics",
                parent:"x-data",
                access:['all'],
                subSection: [
                  {
                    name:"xdata-analytics-buckets",
                    displayName:t('sidebar.Buckets'),
                    level:2,
                    link:"/dashboard/xdata/analytics",
                    parent:"x-data-analytics",
                    mainLink: "/dashboard/x-data",
                    access:['all']
                  },
                  {
                    name:"xdata-analytics-locations",
                    displayName:t('sidebar.Locations'),
                    level:2,
                    link:"/dashboard/xdata/analytics/locations/",
                    parent:"x-data-analytics",
                    mainLink: "/dashboard/x-data",
                    access:['all']
                  }
                ]
            })
            legacy_sub.push({
                name:"legacy-databases-analytics",
                displayName:t('sidebar.Analytics'),
                level:1,
                link:"/dashboard/legacy/analytics",
                parent:"legacy-databases",
                access:['all']
            })
        }

          links.push({
              name:"x-data",
              displayName:t('sidebar.XData'),
              level:0,
              link:"/dashboard/x-data",
              icon:<DataIcon />,
              access:['all'],
              subSection: xdata_sub
          })

          links.push({
              name:"legacy-databases",
              displayName:t('sidebar.LegacyDatabases'),
              level:0,
              link:"/dashboard/legacy-databases",
              icon:<DatabaseIcon />,
              access:['all'],
              subSection: legacy_sub
          })

      }

      if(user?.is_superuser || user?.is_theme_editor){
          links.push({
              name:"themes",
              displayName:t('sidebar.Themes'),
              level:0,
              link:"/dashboard/themes",
              icon:<ThemesIcon />,
              access:['all']
          })
      }

      if(user?.is_colordb_editor || user?.is_historians ||
        user?.is_theme_editor || user?.is_workspace_graph ){

          links.push({
            name:"service-engineer",
            displayName:"Service Engineer",
            level:0,
            link:"/dashboard/service-engineer",
            icon:<EngineeringOutlinedIcon />,
            access:['all']
          })

          links.push({
            name:"complaint-register",
            displayName:"Support",
            level:0,
            link:"/dashboard/complaint-registration",
            icon:<ChatBubbleOutlineOutlinedIcon />,
            access:['all']
          })
        }
  }

    setNavlinks(links)

  }, [user, impersonateUserInfo, t])

  return (
    <SideBarRoot>
      <Box style={{marginBottom: 40,display: 'flex', justifyContent: 'center', marginTop: 20}}>
            <Logo className="appLogo" />
        </Box>
      {navlinks?.map((item,index)=>{
        return(
          <CustomDropDown data={item} key={index} external_link={item.name === "django_admin"?true:false}/>
        )
      })}
    </SideBarRoot>
  )
}