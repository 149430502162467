import React,{useState,useEffect} from 'react'
import { DashboardLayout } from 'layouts'
import { BreadCrumb,CustomTable} from 'components'
import { useTheme } from '@mui/material/styles';
import { Button, Select, MenuItem, Tooltip, Link, useMediaQuery, InputLabel } from "@mui/material";
import Divider from '@mui/material/Divider';
import Modals from 'components/Modal';
import { CustomTextField } from 'elements';
import {Grid} from "@mui/material";
import { useFormik } from "formik";
import {  string} from "yup";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import styles from "./style";
import {RiErrorWarningLine} from "react-icons/ri";
import {AiFillCloseSquare} from "react-icons/ai";
import { IconButton } from '@mui/material';
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { useDispatch, useSelector } from "react-redux"
import {getAllServiceEngineers, createServiceEngineers, editServiceEngineers, uploadServiceEngineers, 
  deleteServiceEngineers, downloadsampleServiceEngineerTemplate } from "../../redux/actions/serviceEngineer/serviceEngineerActions"
import { Spinner } from "reactstrap"
import Swal from 'sweetalert2'
import toast, { Toaster } from 'react-hot-toast';
import {useTranslation} from "react-i18next";
import InputFile from 'components/InputFile';
import { api_settings } from "../../redux/services/api";
import {getCountryList} from "../../redux/actions/auth/registerActions"
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import { AiOutlineCloseCircle} from 'react-icons/ai';

const useStyles = makeStyles(styles)


const SelectContainer = styled('div')(({ theme }) => ({
  '& .label':{
      color:'#000',
      fontFamily:"Roboto",
      fontSize:16,
      fontWeight:500,
      marginBottom:17,
      lineHeight:1,
  },
  [theme.breakpoints.down("xl")]:{
      '& .label':{
      fontSize:`${16-2}px`,
      fontWeight:500,
      marginBottom:12,
      }
  },
  [theme.breakpoints.up("xl")]:{
      '& .label':{
      fontSize:`${20}px`,
      }
  }

}))

const SelectTextField = styled(TextField)(({ theme }) => ({
  backgroundColor:"transparent",
  borderRadius:10,
  minHeight:`${45+23}px`,
  "&  .MuiFormHelperText-root.Mui-error": { //<--- here
      color:"#C8102E"
    },
    "& .MuiInputBase-root":{
      backgroundColor:'#E8E8E8',
      borderRadius:10,
    },
    "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before":{
      borderBottom : 'none !important'
    },
    "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root.Mui-error:after":{
      borderBottom : 'none !important'
    },
    "& .css-1a1fmpi-MuiInputBase-root-MuiInput-root:after":{
      borderBottom : 'none !important'
    },
  input:{
      backgroundColor:'#E8E8E8',
      fontFamily:"Roboto",
      fontSize:18,
      fontWeight:300,
      height:32,
      padding:"0px 42px !important",
      borderRadius:10,
      color:'#000',
      "&::placeholder":{
          color:'#000'
      }, 
  },
  [theme.breakpoints.down("xl")]:{
      minHeight:`${45+18}px`,
      input:{
          fontSize:16,
          padding:"0px 37px",
      },
  },
  [theme.breakpoints.up("xl")]:{
      minHeight:`${45+25}px`,
      input:{
          height:`${45+4}px`,
          fontSize:18,
          padding:"0px 37px",
      },
  }

}))

export default function ServiceEngineerList() {
  const {t} = useTranslation('common');
  const linkData = [ 
    {
        displayName: t('ServiceEngineers.ServiceEngineers'),
        link:"/dashboard/service-engineer"
    }
  ]

  const thead = [
    {
      name:"email",
      displayName:t('ServiceEngineers.Email'),
      type:"text",
      width:30,  //percentage
    },
    {
      name:"firstname",
      displayName:t('ServiceEngineers.firstname'),
      type:"text",
      width:20
    },
    {
      name:"lastname",
      displayName:t('ServiceEngineers.lastname'),
      type:"text",
      width:20
    },
    {
      name:"country",
      displayName:t('ServiceEngineers.country'),
      type:"text",
      width:20
    },
    {
      name:"phone",
      displayName:t('ServiceEngineers.phone'),
      type:"text",
      width:20
    },
    {
      name:"actions",
      displayName:t('ServiceEngineers.Actions'),
      type:"action",
      width:13
    },
  ]
  const classes = useStyles();
  const theme = useTheme();
  const [openServiceEngineer, setOpenServiceEngineer] = useState(false);
  const [opennewServiceEngineer, setnewOpenServiceEngineer] = useState(false);
  const [btnOpenServiceEngineer, setbtnOpenServiceEngineer] = useState(false);
  const [DeleteServiceEngineer,setDeleteServiceEngineer] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(false);


  const [serviceEngineerlist, setServiceEngineerlist] = useState([]);

    const deleteClose = () =>setDeleteServiceEngineer(false);

    const [page, setPage] = useState(1);
    const [deleteServiceEngineerItem, setDeleteServiceEngineerItem] = useState(null);
    const [allusersCount, setAllUsersCount] = useState(0);

  const isSmallerDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const { language } = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const { 
    loadingServiceEngineer,
    serviceEngineers,
    createServiceEngineerLoading,
    createServiceEngineerError,
    createServiceEngineerSuccess,
    updateServiceEngineerLoading,
    updateServiceEngineerSuccess,
    updateServiceEngineerError,
    deleteServiceEngineerSuccess,
    deleteServiceEngineerLoading,
    createServiceEngineerError500,
    updateServiceEngineerError500, 
    deleteServiceEngineerError500,
    downloadServiceEngineerTeplateSuccess,
    downloadServiceEngineerTeplate500Error,
    uploadServiceEngineerSuccess,
    uploadServiceEngineerError,
    uploadServiceEngineerLoading,
    uploadServiceEngineerError500,
    error
  } = useSelector((state) => state.serviceEngineer.serviceEngineer);
  const { user } = useSelector((state) => state.auth.login);
  const {countryListSuccess} = useSelector((state) => state.auth.register);
  const { impersonateUserInfo } = useSelector((state) => state.impersonate.impersonate);
  const forgot = useSelector((state) => state.auth.forgot);

  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState("");

  const [ServiceEngineersanchorElTemp, setServiceEngineersAnchorElTemp] = useState(null);
  const [ServiceEngineerserrorModal, setServiceEngineersErrorModal] = useState(false);
  const [ServiceEngineersuploadErrorData, setServiceEngineersUploadErrorData] = useState();

  const setServiceEngineersErrorClose = () =>setServiceEngineersErrorModal(false);

  useEffect(() => {
    dispatch(getCountryList())
  }, []);
  
  useEffect(() => {    
    if(countryListSuccess) {
        setCountryList(countryListSuccess.data)
    }
  },[countryListSuccess]);

  const handleClick = (event) => {
    setServiceEngineersAnchorElTemp(event.currentTarget);
  };

  const errors = {};
  const validate = values => {
    if (values.dataFile === "") {
      errors.name = t('ServiceEngineers.PleaseSelectafile');
    }
    return errors;
  };
  
  const uploadformik = useFormik({
    initialValues: {    
      dataFile: "", 
    },
    validate,
    onSubmit : (values)=> {
      dispatch(uploadServiceEngineers(values))
    }
  });
  
  const handleOpen = () => {
    if (selectedOption === t('ServiceEngineers.Single')) {
      setOpenServiceEngineer(true)
    } else if (selectedOption === t('ServiceEngineers.Multiple')) {
      setnewOpenServiceEngineer(true)
    } else {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 2000);
    }
  };
  
  useEffect(() => {  
    let serviceengineersList = []
    if(serviceEngineers?.data){
      (serviceEngineers.data).forEach(function(item){
        serviceengineersList.push({
          id: item.id,
          email: item.email,
          firstname: item.firstname,
          lastname: item.lastname,
          country: item.country,
          phone: item.phone,
          ext: item.ext,
          city: item.city,
          company_name: item.company_name,
          address: item.address,
        })
      })
      setServiceEngineerlist(serviceengineersList)
      setAllUsersCount(serviceEngineers.count)
    }
  }, [serviceEngineers])
  

  const handleRowClick = (item) => () => {
    //
  }
     
  const formik = useFormik({
    initialValues: {
    firstname: "",
    lastname: "",
    country: "",
    ext: "",
    phone: "",
    email: "",
    company_name: "",
    city: "",
    address: "",
  },
  validationSchema: Yup.object({    
    firstname: string().required(t('ServiceEngineers.Pleaseenterfirstname')).max(15, t('ServiceEngineers.MaxLength')).matches(/^[\p{L}]+$/u, t('ServiceEngineers.NoSpecialCharacters')), // Allows letters (including Unicode) and digits,
    lastname: string().required(t('ServiceEngineers.Pleaseenterlastname')).max(15, t('ServiceEngineers.MaxLength')).matches(/^[\p{L}]+$/u, t('ServiceEngineers.NoSpecialCharacters')),
    country: string().required(t('ServiceEngineers.Pleaseentercountry')).matches(/^[a-zA-Z]+$/, t('ServiceEngineers.NoSpecialCharacters')),
    ext: string().required(t('ServiceEngineers.Pleaseenterext')).max(5, t('ServiceEngineers.ExtMaxLength')).matches(/^\+\d{1,5}$/, t('ServiceEngineers.Extsign')),
    phone: string().required(t('ServiceEngineers.Pleaseenterphone')).max(20, t('ServiceEngineers.PhoneMaxLength')).matches(/^\d+$/, t('ServiceEngineers.PhoneNumberOnly')),
    email: string().email(t('ServiceEngineers.Invalidemail')),
    company_name: string().required(t('ServiceEngineers.Pleaseentercompanyname')),
    city: string().required(t('ServiceEngineers.Pleaseentercity')).matches(/^[a-zA-Z]+$/, t('ServiceEngineers.NoSpecialCharacters')),
    address: string(),
  }),
  onSubmit : (values, formikHelpers)=> {
      dispatch(createServiceEngineers(values)) 
  }
  });

  const editformik = useFormik({
    initialValues: {
      editid: "",
      editfirstname: "",
      editlastname: "",
      editcountry: "",
      editext: "",
      editphone: "",
      editemail: "",
      editcompany_name: "",
      editcity: "",
      editaddress: "",
  },
  validationSchema: Yup.object({   
    editfirstname: string().required(t('ServiceEngineers.Pleaseenterfirstname')).max(15, t('ServiceEngineers.MaxLength')).matches(/^[\p{L}]+$/u, t('ServiceEngineers.NoSpecialCharacters')), // Allows letters (including Unicode) and digits,
    editlastname: string().required(t('ServiceEngineers.Pleaseenterlastname')).max(15, t('ServiceEngineers.MaxLength')).matches(/^[\p{L}]+$/u, t('ServiceEngineers.NoSpecialCharacters')),
    editcountry: string().required(t('ServiceEngineers.Pleaseentercountry')),
    editext: string().required(t('ServiceEngineers.Pleaseenterext')).max(5, t('ServiceEngineers.ExtMaxLength')).matches(/^\+\d{1,5}$/, t('ServiceEngineers.Extsign')),
    editphone: string().required(t('ServiceEngineers.Pleaseenterphone')).max(20, t('ServiceEngineers.PhoneMaxLength')).matches(/^\d+$/, t('ServiceEngineers.PhoneNumberOnly')),
    editemail: string().email(t('ServiceEngineers.Invalidemail')),
    editcompany_name: string().required(t('ServiceEngineers.Pleaseentercompanyname')),
    editcity: string().required(t('ServiceEngineers.Pleaseentercity')).matches(/^[a-zA-Z]+$/, t('ServiceEngineers.NoSpecialCharacters')),
    editaddress: string(),
  }),
  onSubmit : (values, formikHelpers)=> {
    if(values.editid !== "" ){
      dispatch(editServiceEngineers(values))
    }
    
  },
  });

  const buttonClose = () => {
    setbtnOpenServiceEngineer(false)
    uploadformik.resetForm()
  };

  const handleClose = () => {
    formik.resetForm()
    uploadformik.resetForm()
    setOpenServiceEngineer(false)
  };

  const handleuploadClose = () => {
    formik.resetForm()
    uploadformik.resetForm()
    setnewOpenServiceEngineer(false)
    
  };

  const errorBlockBtnClick = () => {
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllServiceEngineers(payload)) 
  }

  useEffect(() => {  
    let paginationPage = page;
    let paginationOffset = 0;
    if(page > 1){
      paginationPage = paginationPage - 1;
      paginationPage = paginationPage * 10;
      paginationOffset = paginationPage;
    }
    let payload = {
      offset: paginationOffset,
    }  
    
    dispatch(getAllServiceEngineers(payload))  

    if(createServiceEngineerSuccess?.status === "success"){
      setOpenServiceEngineer(false)
      Swal.fire({
        title: t('ServiceEngineers.Success'),
        text: t('ServiceEngineers.AccountCreated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      formik.resetForm()
    }

    if(updateServiceEngineerSuccess?.status === "success"){
      setbtnOpenServiceEngineer(false)
      Swal.fire({
        title: t('ServiceEngineers.Success'),
        text: t('ServiceEngineers.AccountUpdated'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      
    }

  },[page, createServiceEngineerSuccess,updateServiceEngineerSuccess, dispatch, t]);

  useEffect(() => {
    if(uploadServiceEngineerSuccess){
      setnewOpenServiceEngineer(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllServiceEngineers(payload)) 
      Swal.fire({
        title: t('ServiceEngineers.Success'),
        text: t('ServiceEngineers.ServiceEngineeruploadedsuccessfully'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
      dispatch({
        type: "REQUEST_UPLOAD_SERVICE_ENGINEER_SUCCESS",
        payload: null
      });
      uploadformik.resetForm()
    }

    if(uploadServiceEngineerError) {
      setnewOpenServiceEngineer(false)
      setServiceEngineersErrorModal(true)
      setServiceEngineersUploadErrorData(uploadServiceEngineerError)
      uploadformik.resetForm()
      dispatch({
        type: "REQUEST_UPLOAD_SERVICE_ENGINEER_FAILED",
        payload: null
      })
    }
    
  }, [uploadServiceEngineerSuccess, uploadServiceEngineerError])


  useEffect(() => {  
  
    if(deleteServiceEngineerSuccess){
      setDeleteServiceEngineer(false)
      let payload = {
        offset: 0
      } 
      setPage(1)
      dispatch(getAllServiceEngineers(payload)) 
      Swal.fire({
        title: t('ServiceEngineers.Success'),
        text: t('ServiceEngineers.AccountDeleted'),
        icon: 'success',
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E'
      })
    }

  },[deleteServiceEngineerSuccess, dispatch, t]);

  useEffect(() => {  

    if(createServiceEngineerError){
      // Access the first error message
      let errorMessage = createServiceEngineerError.error[0];

      // Extract the part after "error :"
      let extractedMessage = errorMessage.includes('error :') ? errorMessage.split('error :')[1].trim() : errorMessage;

      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: extractedMessage === "Service Engineer data already exists!"? t('ServiceEngineers.userwiththisdetailsalreadyexists'): 
        extractedMessage === "Phone No of Service Engineer already exists!"? t('ServiceEngineers.userwiththisphonenumberalreadyexists'): 
        extractedMessage === "Email address of Service Engineer already exists!"? t('ServiceEngineers.userwiththisemailaddressalreadyexists'):null,
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',

      })
      dispatch({
        type: "CREATE_REQUEST_SERVICE_ENGINEER_FAILED",
        payload: null
      })
    }

  },[createServiceEngineerError, dispatch, t]);


  useEffect(() => {  

    if(updateServiceEngineerError){
      // Access the first error message
      let errorMessage = updateServiceEngineerError[0];

      // Extract the part after "error :"
      let extractedMessage = errorMessage.includes('error :') ? errorMessage.split('error :')[1].trim() : errorMessage;

      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: extractedMessage === "Service Engineer data already exists!"? t('ServiceEngineers.userwiththisdetailsalreadyexists'): 
        extractedMessage === "Phone No of Service Engineer already exists!"? t('ServiceEngineers.userwiththisphonenumberalreadyexists'): 
        extractedMessage === "Email address of Service Engineer already exists!"? t('ServiceEngineers.userwiththisemailaddressalreadyexists'):null,
        iconColor: '#C8102E',
        confirmButtonColor: '#C8102E',

      })
      dispatch({
        type: "UPDATE_REQUEST_SERVICE_ENGINEER_FAILED",
        payload: null
      })
    }

  },[updateServiceEngineerError, dispatch, t]);


  const handleDeleteClick = (item) => () => {
    setDeleteServiceEngineer(true)
    setDeleteServiceEngineerItem(item)
  }

  const deleteConfirm = () => {   
    dispatch(deleteServiceEngineers({id: deleteServiceEngineerItem.id}))
  }

  useEffect(() => {  
    if(createServiceEngineerError500){
      toast.error(t('ServiceEngineers.userCreateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "CREATE_REQUEST_SERVICE_ENGINEER_FAILED_500",
        payload: null
      })
    }

    if(updateServiceEngineerError500){
      toast.error(t('ServiceEngineers.userUpdateFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "UPDATE_REQUEST_SERVICE_ENGINEER_FAILED_500",
        payload: null
      })
    }

    if(uploadServiceEngineerError500){
      toast.error(t('ServiceEngineers.uploadFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_UPLOAD_SERVICE_ENGINEER_FAILED_500",
        payload: null
      })
    }

    if(deleteServiceEngineerError500){
      toast.error(t('ServiceEngineers.userDeleteFailed') , {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "DELETE_REQUEST_SERVICE_ENGINEER_FAILED_500",
        payload: null
      })
    }

    if(downloadServiceEngineerTeplate500Error){
      toast.error(t('ServiceEngineers.downloadServiceEngineerTeplateFailed'), {
        duration: 8000,
        position: 'top-right',
        style: {fontSize: 20, fontFamily: 'Roboto'}
      });
      dispatch({
        type: "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_FAILED_500",
        payload: null
      })
    }
    
  }, [createServiceEngineerError500, updateServiceEngineerError500, uploadServiceEngineerError500, deleteServiceEngineerError500, downloadServiceEngineerTeplate500Error, dispatch])



  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    // Additional logic to handle state change
  };

  const headerActions = (
    <>
    <Select
      className={classes.select}
      value={selectedOption}
      onChange={handleChange}
      displayEmpty
      sx={{ border: '1px solid #000', borderRadius: '4px', height: '44px', width:"160px" }}>
      <MenuItem className={classes.menuitem} value="">Select an option</MenuItem>
      <MenuItem className={classes.menuitem} value={t('ServiceEngineers.Single')}>{t('ServiceEngineers.Single')}</MenuItem>
      <MenuItem className={classes.menuitem} value={t('ServiceEngineers.Multiple')}>{t('ServiceEngineers.Multiple')}</MenuItem>
    </Select>
    <Tooltip
      title="Please select an option"
      open={tooltipOpen}
      disableHoverListener
      disableFocusListener
      disableTouchListener
      placement="top"
    >
    <Button onClick={handleOpen} style={{marginLeft:30}}><PlusIcon /> {t('ServiceEngineers.AddNew')}</Button>
    </Tooltip>
    </>
  )

  const handleDownloadsampleServiceEngineerTemplate = () => {            
    dispatch(downloadsampleServiceEngineerTemplate()) 
  }

  useEffect(() => {  
    if(downloadServiceEngineerTeplateSuccess){
      const downloadLink = document.createElement('a');
      downloadLink.href = api_settings.url_base + downloadServiceEngineerTeplateSuccess.data.url;

      // Append the link to the body and trigger a click
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Cleanup: Remove the link from the DOM
      document.body.removeChild(downloadLink);
      dispatch({
        type: "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_SUCCESS",
        payload: null
      });

    }
  }, [downloadServiceEngineerTeplateSuccess])

  const bigHtmlContentupload = (
    <>
      <form onSubmit={uploadformik.handleSubmit}>
      <div className={classes.main}>                 
          <div className={classes.text3} >{t('ServiceEngineers.ServiceEngineerForm')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleuploadClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
    <Grid item sm={12}>
    <p><b>{t('ServiceEngineers.note')} : </b>{t('ServiceEngineers.note1')} 
    <b style={{color:'#C8102E'}}>
      [ 
      <Link sx={{ "&:hover": { color: "#C8102E" }, cursor: "pointer" }} color={"#C8102E"} 
      onClick={handleDownloadsampleServiceEngineerTemplate}> {t('ServiceEngineers.note2')} </Link>
      ]
    </b>
    </p> 
    </Grid>
    <Grid item sm={12} style={{color:'#C8102E'}}>
      <ul style={{Padding:'10px'}} >
        <li color='#C8102E'>{t('ServiceEngineers.note3')}</li>
        <li color='#C8102E'>{t('ServiceEngineers.note4')}</li>
        <li color='#C8102E'>{t('ServiceEngineers.note5')}</li>
      </ul>  
    </Grid>
    <Grid item sm={12}></Grid>
    <Grid className={classes.grid} item xs={12}>
        <h3 className='box-title'>{t('ServiceEngineers.ServiceEngineerDataUpload')}</h3> 
    </Grid> 

    <Grid item sm={12}>
      <InputFile 
          btnText={t('ServiceEngineers.ChooseFile')}
          formik={uploadformik}
          onBlur={uploadformik.handleBlur} 
          value={uploadformik.values.dataFile}
          name="dataFile"
        />
    </Grid>

    </Grid>
              <div>
                  <Button   
                        className="appBtn"  
                        style={{marginTop: "unset"}}              
                        size="small"
                        onClick={handleClick}
                        type={'submit'}
                      >
                  {uploadServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.Create')}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )
  
  const bigHtmlContentuploadImp = (
    <>
    <form onSubmit={uploadformik.handleSubmit}>
      <div className={classes.main}>                 
          <div className={classes.text3} >{t('ServiceEngineers.ServiceEngineerForm')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleuploadClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
    <Grid item sm={12}>
    <p><b>{t('ServiceEngineers.note')} : </b>{t('ServiceEngineers.note1')} 
    <b style={{color:'#C8102E'}}>
      [ 
      <Link sx={{ "&:hover": { color: "#C8102E" }, cursor: "pointer" }} color={"#C8102E"} 
      onClick={handleDownloadsampleServiceEngineerTemplate}> {t('ServiceEngineers.note2')} </Link>
      ]
    </b>
    </p> 
    </Grid>
    <Grid item sm={12} style={{color:'#C8102E'}}>
      <ul style={{Padding:'10px'}} >
        <li color='#C8102E'>{t('ServiceEngineers.note3')}</li>
        <li color='#C8102E'>{t('ServiceEngineers.note4')}</li>
        <li color='#C8102E'>{t('ServiceEngineers.note5')}</li>
      </ul>  
    </Grid>
    <Grid item sm={12}></Grid>
    <Grid className={classes.grid} item xs={12}>
        <h3 className='box-title'>{t('ServiceEngineers.ServiceEngineerDataUpload')}</h3> 
    </Grid> 

    <Grid item sm={12}>
      <InputFile 
          btnText={t('ServiceEngineers.ChooseFile')}
          formik={uploadformik}
          onBlur={uploadformik.handleBlur} 
          value={uploadformik.values.dataFile}
          name="dataFile"
        />
    </Grid>

    </Grid>
              <div>                   
              <Button   
                className="appBtn"  
                style={{marginTop: "unset"}}              
                size="small"
                onClick={handleClick}
                type={'submit'}
              >
                  {uploadServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.Create')}
                </Button>                                                               
              </div>                            
      </form>              
    </>
  )

  function extractString(errorDetail) {
    const regex = /string='([^']*)'/;
    const match = errorDetail.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }

  function extractStringInvalid(text) {
    return text.split(':')[1].trim();
  }

  const uploadErrorContent = (
    <div style={{width: 500, fontFamily:"Roboto",}}>
    
    <div className={classes.logo1}>
        <AiOutlineCloseCircle color="#C8102E" size={"100px"} />
    </div>
    <Accordion style={{boxShadow: 'unset', background: "#f2dede", padding:15}}>
        <div >
            <p >{t('ServiceEngineers.ServiceEngineerUploadFailed')}</p>
            <p style={{marginTop: 10}}>{ ServiceEngineersuploadErrorData ? ServiceEngineersuploadErrorData.startsWith("non_field_errors") ? 
            extractStringInvalid(ServiceEngineersuploadErrorData) : extractString(ServiceEngineersuploadErrorData) : null }</p>
            </div>
        </Accordion>
    <div className={classes.button2}>
           
            <div className={classes.ok}>
                <Button                         
                  size="large"
                  onClick={() => setServiceEngineersErrorModal(false)}
                >
                    {t('ServiceEngineers.OK')}
                </Button>
            </div>
    </div>
  </div>     
  )

  const handleEditClick = (item) => () => {
    editformik.setFieldValue("editfirstname", item.firstname)
    editformik.setFieldValue("editlastname", item.lastname)
    editformik.setFieldValue("editcountry", item.country)
    editformik.setFieldValue("editext", item.ext)
    editformik.setFieldValue("editphone", item.phone)
    editformik.setFieldValue("editemail", item.email)
    editformik.setFieldValue("editcompany_name", item.company_name)
    editformik.setFieldValue("editcity", item.city)
    editformik.setFieldValue("editaddress", item.address)
    editformik.setFieldValue("editid", item.id)
    setbtnOpenServiceEngineer(true)
  }

  const bigHtmlContent = (
    <>
    <form onSubmit={formik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('ServiceEngineers.ServiceEngineerForm')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.firstname')}
            name="firstname"
            type="name"
            placeholder="Firstname"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.firstname} 
            error = {formik.touched.firstname && Boolean(formik.errors.firstname)} 
            helperText = {formik.touched.firstname && formik.errors.firstname}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.lastname')}
            name="lastname"
            type="name"
            placeholder="Lastname"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.lastname} 
            error = {formik.touched.lastname && Boolean(formik.errors.lastname)} 
            helperText = {formik.touched.lastname && formik.errors.lastname}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.Company')}
            name="company_name"
            type="name"
            placeholder="Company"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.company_name} 
            error = {formik.touched.company_name && Boolean(formik.errors.company_name)} 
            helperText = {formik.touched.company_name && formik.errors.company_name}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.Email')}
            name="email"
            type="name"
            placeholder="Email"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.email} 
            error = {formik.touched.email && Boolean(formik.errors.email)} 
            helperText = {formik.touched.email && formik.errors.email}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={false} />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.ext')}
            name="ext"
            type="name"
            placeholder="Ext"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.ext} 
            error = {formik.touched.ext && Boolean(formik.errors.ext)} 
            helperText = {formik.touched.ext && formik.errors.ext}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.phone')}
            name="phone"
            type="name"
            placeholder="Phone"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.phone} 
            error = {formik.touched.phone && Boolean(formik.errors.phone)} 
            helperText = {formik.touched.phone && formik.errors.phone}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>                       
          <Autocomplete
            sx={{ width: '100%' }}
            options={countryList}
            autoHighlight
            getOptionLabel={(option) => option.country_name}                   
            renderOption={(props, option) => (
                <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                
                {option.country_name}
                </Box>
            )}
            onChange={(event, newValue) => {
              setCountryCode(JSON.stringify(newValue.country_code))
              }}
            renderInput={(params) => (
                
                
                <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                    <InputLabel className='label' required={true}>{t('ServiceEngineers.country')}</InputLabel>
                    <SelectTextField
                    variant="standard"
                    type="text"                                
                    name="country"
                    id="as"
                    {...params}
                    placeholder={t('ServiceEngineers.country')}
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={() =>formik.setFieldValue("country", params.inputProps.value)}
                    // onFocus={() =>formik.setFieldValue("country", params.inputProps.value)}
                    error = {formik.touched.country && Boolean(formik.errors.country)}
                    helperText = {formik.touched.country && formik.errors.country}
                    inputProps={{
                        
                        ...params.inputProps,
                        // autoComplete: 'new-password'
                    }}
                    />
                </SelectContainer>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.city')}
            name="city"
            type="name"
            placeholder="City"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.city} 
            error = {formik.touched.city && Boolean(formik.errors.city)} 
            helperText = {formik.touched.city && formik.errors.city}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={t('ServiceEngineers.address')}
            name="address"
            type="name"
            placeholder="Address"
            onChange={formik.handleChange} 
            onBlur={formik.handleBlur} 
            value={formik.values.address} 
            error = {formik.touched.address && Boolean(formik.errors.address)} 
            helperText = {formik.touched.address && formik.errors.address}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={false} />
        </Grid>
      </Grid>
    <div>                   
      <Button  
      className='appBtn'
        size="meduim"
        type="submit"
      >
        {createServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.Create')}
      </Button>                                                               
    </div>
    </form>
    </>
  )
  
  const bigHtmlContentImp = (
    <>
    <form onSubmit={formik.handleSubmit}>
     <div className={classes.main}>                 
          <div className={classes.text3} >{t('ServiceEngineers.ServiceEngineerForm')}</div>                               
          <div className={classes.button3}>
              <IconButton onClick={()=> handleClose()}>
                  <AiFillCloseSquare color='#C8102E' size="30px"/>
              </IconButton>
          </div>                
      </div>
    <Divider className={classes.divide} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.firstname')}
              name="firstname"
              type="name"
              placeholder="Firstname"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.firstname} 
              error = {formik.touched.firstname && Boolean(formik.errors.firstname)} 
              helperText = {formik.touched.firstname && formik.errors.firstname}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              handleDeleteClick={handleDeleteClick}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.lastname')}
              name="lastname"
              type="name"
              placeholder="Lastname"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.lastname} 
              error = {formik.touched.lastname && Boolean(formik.errors.lastname)} 
              helperText = {formik.touched.lastname && formik.errors.lastname}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              handleDeleteClick={handleDeleteClick}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.Company')}
              name="company_name"
              type="name"
              placeholder="Company"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.company_name} 
              error = {formik.touched.company_name && Boolean(formik.errors.company_name)} 
              helperText = {formik.touched.company_name && formik.errors.company_name}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.Email')}
              name="email"
              type="name"
              placeholder="Email"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.email} 
              error = {formik.touched.email && Boolean(formik.errors.email)} 
              helperText = {formik.touched.email && formik.errors.email}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              handleDeleteClick={handleDeleteClick}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E"
              required={false} />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.ext')}
              name="ext"
              type="name"
              placeholder="Ext"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.ext} 
              error = {formik.touched.ext && Boolean(formik.errors.ext)} 
              helperText = {formik.touched.ext && formik.errors.ext}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              handleDeleteClick={handleDeleteClick}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E" />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.phone')}
              name="phone"
              type="name"
              placeholder="Phone"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.phone} 
              error = {formik.touched.phone && Boolean(formik.errors.phone)} 
              helperText = {formik.touched.phone && formik.errors.phone}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              handleDeleteClick={handleDeleteClick}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E" />
          </Grid>
          <Grid item xs={6}>                       
            <Autocomplete
              sx={{ width: '100%' }}
              options={countryList}
              autoHighlight
              getOptionLabel={(option) => option.country_name}                   
              renderOption={(props, option) => (
                  <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  
                  {option.country_name}
                  </Box>
              )}
              onChange={(event, newValue) => {
                setCountryCode(JSON.stringify(newValue.country_code))
                }}
              renderInput={(params) => (
                  
                  
                  <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                      <InputLabel className='label' required={true}>{t('ServiceEngineers.country')}</InputLabel>
                      <SelectTextField
                      variant="standard"
                      type="text"                                
                      name="country"
                      id="as"
                      {...params}
                      placeholder={t('ServiceEngineers.country')}
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={() =>formik.setFieldValue("country", params.inputProps.value)}
                      // onFocus={() =>formik.setFieldValue("country", params.inputProps.value)}
                      error = {formik.touched.country && Boolean(formik.errors.country)}
                      helperText = {formik.touched.country && formik.errors.country}
                      inputProps={{
                          
                          ...params.inputProps,
                          autoComplete: 'new-password'
                      }}
                      />
                  </SelectContainer>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              label={t('ServiceEngineers.city')}
              name="city"
              type="name"
              placeholder="City"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.city} 
              error = {formik.touched.city && Boolean(formik.errors.city)} 
              helperText = {formik.touched.city && formik.errors.city}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              handleDeleteClick={handleDeleteClick}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E" />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label={t('ServiceEngineers.address')}
              name="address"
              type="name"
              placeholder="Address"
              onChange={formik.handleChange} 
              onBlur={formik.handleBlur} 
              value={formik.values.address} 
              error = {formik.touched.address && Boolean(formik.errors.address)} 
              helperText = {formik.touched.address && formik.errors.address}
              height={45}
              labelColor="#000"
              labelTextSize={16}
              inputbackgroundcolor="#E8E8E8"
              inputtextcolor="#000"
              placeholdercolor="#A5A5A5"
              helpertextcolor="#C8102E"
              required={false} />
          </Grid>
      </Grid>
      <div>                   
        <Button  
        className='appBtn'
          size="meduim"
          type="submit"
        >
          {createServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.Create')}
        </Button>
      </div>
    </form>
    </>
  )

  const bigHtmlContent1 = (
    <>
    <form onSubmit={editformik.handleSubmit}>
    <div className={classes.head}>{t('ServiceEngineers.EditServiceEngineer')}</div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.firstname')}
            name="editfirstname"
            type="name"
            placeholder="Firstname"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editfirstname} 
            error = {editformik.touched.editfirstname && Boolean(editformik.errors.editfirstname)} 
            helperText = {editformik.touched.editfirstname && editformik.errors.editfirstname}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.lastname')}
            name="editlastname"
            type="name"
            placeholder="Lastname"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editlastname} 
            error = {editformik.touched.editlastname && Boolean(editformik.errors.editlastname)} 
            helperText = {editformik.touched.editlastname && editformik.errors.editlastname}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.Company')}
            name="editcompany_name"
            type="name"
            placeholder="Company"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editcompany_name} 
            error = {editformik.touched.editcompany_name && Boolean(editformik.errors.editcompany_name)} 
            helperText = {editformik.touched.editcompany_name && editformik.errors.editcompany_name}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.Email')}
            name="editemail"
            type="name"
            placeholder="Email"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editemail} 
            error = {editformik.touched.editemail && Boolean(editformik.errors.editemail)} 
            helperText = {editformik.touched.editemail && editformik.errors.editemail}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={false} />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.ext')}
            name="editext"
            type="name"
            placeholder="Ext"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editext} 
            error = {editformik.touched.editext && Boolean(editformik.errors.editext)} 
            helperText = {editformik.touched.editext && editformik.errors.editext}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.phone')}
            name="editphone"
            type="name"
            placeholder="Phone"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editphone} 
            error = {editformik.touched.editphone && Boolean(editformik.errors.editphone)} 
            helperText = {editformik.touched.editphone && editformik.errors.editphone}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>                       
          <Autocomplete
            sx={{ width: '100%' }}
            options={countryList}
            autoHighlight
            getOptionLabel={(option) => option.country_name}                   
            renderOption={(props, option) => (
                <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.country_name}
                </Box>
            )}
            onChange={(event, newValue) => {
              setCountryCode(JSON.stringify(newValue.country_code))
              }}
            defaultValue={editformik.values.editcountry === "" ?{country_name: editformik.values.editcountry}:{ country_name: editformik.values.editcountry ? editformik.values.editcountry : ""}}

            renderInput={(params) => (
                <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                    <InputLabel className='label' required={true}>{t('ServiceEngineers.country')}</InputLabel>
                    <SelectTextField
                      variant="standard"
                      type="text"                                
                      name="editcountry"
                      id="as"
                      {...params}
                      placeholder={t('ServiceEngineers.country')}
                      value={editformik.values.editcountry}
                      onChange={editformik.handleChange}
                      onBlur={() =>editformik.setFieldValue("editcountry", params.inputProps.value)}
                      // onFocus={() =>formik.setFieldValue("editcountry", params.inputProps.value)}
                      error = {editformik.touched.editcountry && Boolean(editformik.errors.editcountry)}
                      helperText = {editformik.touched.editcountry && editformik.errors.editcountry}
                      inputProps={{
                          ...params.inputProps,
                          // autoComplete: 'new-password'
                    }}
                    />
                </SelectContainer>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.city')}
            name="editcity"
            type="name"
            placeholder="City"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editcity} 
            error = {editformik.touched.editcity && Boolean(editformik.errors.editcity)} 
            helperText = {editformik.touched.editcity && editformik.errors.editcity}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={t('ServiceEngineers.address')}
            name="editaddress"
            type="name"
            placeholder="Address"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editaddress} 
            error = {editformik.touched.editaddress && Boolean(editformik.errors.editaddress)} 
            helperText = {editformik.touched.editaddress && editformik.errors.editaddress}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={false} />
        </Grid>
      </Grid>
       <div className={classes.button}>
          <Button  
          onClick={buttonClose}
          className="appBtnCancel"                                    
          size="large"
          >
          {t('ServiceEngineers.Cancel')}
          </Button>
          <div className={classes.update}>
              <Button 
                className='appBtn'                        
                size="large"
                type="submit"
              >
                {updateServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.Update')}  
              </Button>
          </div>
        </div>
      </form>
    </>                            
  )

  const bigHtmlContentImp1 = (
    <>
    <form onSubmit={editformik.handleSubmit}>
    <div className={classes.head}>{t('ServiceEngineers.EditServiceEngineer')}</div>
    <Divider className={classes.divide} />
    <Grid container rowSpacing={1} columnSpacing={{ xs: 7, sm: 8, md: 9 }} justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.firstname')}
            name="editfirstname"
            type="name"
            placeholder="Firstname"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editfirstname} 
            error = {editformik.touched.editfirstname && Boolean(editformik.errors.editfirstname)} 
            helperText = {editformik.touched.editfirstname && editformik.errors.editfirstname}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.lastname')}
            name="editlastname"
            type="name"
            placeholder="Lastname"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editlastname} 
            error = {editformik.touched.editlastname && Boolean(editformik.errors.editlastname)} 
            helperText = {editformik.touched.editlastname && editformik.errors.editlastname}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.Company')}
            name="editcompany_name"
            type="name"
            placeholder="Company"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editcompany_name} 
            error = {editformik.touched.editcompany_name && Boolean(editformik.errors.editcompany_name)} 
            helperText = {editformik.touched.editcompany_name && editformik.errors.editcompany_name}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.Email')}
            name="editemail"
            type="name"
            placeholder="Email"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editemail} 
            error = {editformik.touched.editemail && Boolean(editformik.errors.editemail)} 
            helperText = {editformik.touched.editemail && editformik.errors.editemail}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={false} />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.ext')}
            name="editext"
            type="name"
            placeholder="Ext"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editext} 
            error = {editformik.touched.editext && Boolean(editformik.errors.editext)} 
            helperText = {editformik.touched.editext && editformik.errors.editext}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.phone')}
            name="editphone"
            type="name"
            placeholder="Phone"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editphone} 
            error = {editformik.touched.editphone && Boolean(editformik.errors.editphone)} 
            helperText = {editformik.touched.editphone && editformik.errors.editphone}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={6}>                       
          <Autocomplete
            sx={{ width: '100%' }}
            options={countryList}
            autoHighlight
            getOptionLabel={(option) => option.country_name}                   
            renderOption={(props, option) => (
                <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.country_name}
                </Box>
            )}
            onChange={(event, newValue) => {
              setCountryCode(JSON.stringify(newValue.country_code))
              }}
            defaultValue={editformik.values.editcountry === "" ?{country_name: editformik.values.editcountry}:{ country_name: editformik.values.editcountry ? editformik.values.editcountry : ""}}

            renderInput={(params) => (
                <SelectContainer labelColor={'#FFFFFF'} labelTextSize={16}>
                    <InputLabel className='label' required={true}>{t('ServiceEngineers.country')}</InputLabel>
                    <SelectTextField
                      variant="standard"
                      type="text"                                
                      name="editcountry"
                      id="as"
                      {...params}
                      placeholder={t('ServiceEngineers.country')}
                      value={editformik.values.editcountry}
                      onChange={editformik.handleChange}
                      onBlur={() =>editformik.setFieldValue("editcountry", params.inputProps.value)}
                      // onFocus={() =>formik.setFieldValue("editcountry", params.inputProps.value)}
                      error = {editformik.touched.editcountry && Boolean(editformik.errors.editcountry)}
                      helperText = {editformik.touched.editcountry && editformik.errors.editcountry}
                      inputProps={{
                          ...params.inputProps,
                          // autoComplete: 'new-password'
                    }}
                    />
                </SelectContainer>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            label={t('ServiceEngineers.city')}
            name="editcity"
            type="name"
            placeholder="City"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editcity} 
            error = {editformik.touched.editcity && Boolean(editformik.errors.editcity)} 
            helperText = {editformik.touched.editcity && editformik.errors.editcity}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            handleDeleteClick={handleDeleteClick}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E" />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            label={t('ServiceEngineers.address')}
            name="editaddress"
            type="name"
            placeholder="Address"
            onChange={editformik.handleChange} 
            onBlur={editformik.handleBlur} 
            value={editformik.values.editaddress} 
            error = {editformik.touched.editaddress && Boolean(editformik.errors.editaddress)} 
            helperText = {editformik.touched.editaddress && editformik.errors.editaddress}
            height={45}
            labelColor="#000"
            labelTextSize={16}
            inputbackgroundcolor="#E8E8E8"
            inputtextcolor="#000"
            placeholdercolor="#A5A5A5"
            helpertextcolor="#C8102E"
            required={false} />
        </Grid>
      </Grid>
       <div className={classes.button}>
          <Button  
          onClick={buttonClose}
          className="appBtnCancel"                                    
          size="large"
          >
          {t('ServiceEngineers.Cancel')}
          </Button>
          <div className={classes.update}>
              <Button 
                className='appBtn'                        
                size="large"
                type="submit"
              >
                {updateServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.Update')}  
              </Button>
          </div>
        </div>
      </form>
    </>                            
  )

  const bigHtmlContent3 = (
    <>
      <div className={classes.logo1}>     
          <RiErrorWarningLine color="#C8102E" size={"100px"} />    
      </div>
      <div className={classes.warning}>
      {t('ServiceEngineers.AreYouSure')}
      </div>
      <div className={classes.text} >
              
      {t('ServiceEngineers.YouareabouttodeleteaServiceEngineers')}
              
      </div>
      
      <div className={classes.button2}>
       
              <Button  
              className="appBtnCancel"  
                onClick={deleteClose}                         
                size="large"
              >
                 {t('ServiceEngineers.Cancel')}
              </Button>
              <div className={classes.ok}>
                  <Button   
                  className='appBtn'                                       
                    size="large"
                    onClick={deleteConfirm}
                  >
                    {deleteServiceEngineerLoading ? <Spinner size="sm">{t('common.Loading')}</Spinner> : t('ServiceEngineers.OK')} 
                  </Button>
              </div>
           
      </div>
     
    </>     
  )
  
  if(impersonateUserInfo?.status === "success"){
    return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loadingServiceEngineer}
              totalPageCount={allusersCount}
              title ={t('ServiceEngineers.ServiceEngineers')}
              thead={thead}
              tdata={serviceEngineerlist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:[],
                        default:["edit","delete"]
                      }
                    }
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              headerActions={headerActions}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={-1}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
          handleclose={handleClose} 
          open={openServiceEngineer} 
          content={bigHtmlContentImp}                   
      />
      <Modals 
          handleclose={handleuploadClose} 
          open={opennewServiceEngineer} 
          content={bigHtmlContentuploadImp}                   
      />
      <Modals 
        handleclose={buttonClose} 
        open={btnOpenServiceEngineer} 
        content={bigHtmlContentImp1}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={DeleteServiceEngineer} 
        content={bigHtmlContent3}                   
      />
      <Modals 
        handleclose={setServiceEngineersErrorClose} 
        open={ServiceEngineerserrorModal} 
        content={uploadErrorContent}                   
      />
      </DashboardLayout>
    )
    }
  else{
    return (
      <DashboardLayout pageLoading={forgot.loading}>
        <Toaster />
          <BreadCrumb linkData={linkData}/>
          <CustomTable 
              loading={loadingServiceEngineer}
              totalPageCount={allusersCount}
              title ={t('ServiceEngineers.ServiceEngineers')}
              thead={thead}
              tdata={serviceEngineerlist}
              maxRow={10}
              handleRowClick={handleRowClick}
              height={isSmallerDesktop?683:813}
              action={
                      {
                        admin:[],
                        default:["edit","delete"]
                      }
                    }
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              headerActions={headerActions}
              loadPagination={setPage}
              currentPage={page}
              clickableColumn={-1}
              errorStatus={error}
              errorBlockBtnClick={errorBlockBtnClick}
          />
      <Modals 
          handleclose={handleClose} 
          open={openServiceEngineer} 
          content={bigHtmlContent}                   
      />
      <Modals 
          handleclose={handleuploadClose} 
          open={opennewServiceEngineer} 
          content={bigHtmlContentupload}                   
      />
      <Modals 
        handleclose={buttonClose} 
        open={btnOpenServiceEngineer} 
        content={bigHtmlContent1}                   
      />
      <Modals 
        handleclose={deleteClose} 
        open={DeleteServiceEngineer} 
        content={bigHtmlContent3}                   
      />
      <Modals  
        handleclose={setServiceEngineersErrorClose} 
        open={ServiceEngineerserrorModal} 
        content={uploadErrorContent}                   
      />
      </DashboardLayout>
    )
  }
}
