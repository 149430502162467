import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'

 export const getAllBuckets = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_ALL_BUCKETS"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.xdataBuckets + '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_ALL_BUCKETS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_ALL_BUCKETS_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const createBucket = (payload, companyid) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_CREATE_BUCKET"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.xdataBuckets, {
        "name": ''+payload.bucket+'',
        "company": ''+companyid+''
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_CREATE_BUCKET_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_CREATE_BUCKET_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_CREATE_BUCKET_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const updateBucket = (payload, companyid, bucketCode) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_UPDATE_BUCKET"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.xdataBuckets + bucketCode + "/", {
        "name": ''+payload.bucket+'',
        "company": ''+companyid+''
      }, config)
      .then(response => {
        dispatch({
          type: "REQUEST_UPDATE_BUCKET_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_UPDATE_BUCKET_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_UPDATE_BUCKET_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const getBucketData = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_BUCKET_DATA"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.xdataBucket +'?bucket_code='+payload.bucket_code+'&limit='+payload.limit+'&offset='+payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_BUCKET_DATA_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_BUCKET_DATA_FAILED",
          payload: err.response.status
        })
      })
  }
}

export const uploadXdata = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_UPLOAD_XDATA"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Content-Type":'multipart/form-data',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    var bodyFormData = new FormData();
    bodyFormData.append('xdata', payload.xdatafile);
  
    axios
      .post(api_settings.url_base + api_endpoints.xdataUpload + payload.code + '/',  bodyFormData, config)
      .then(response => {
        dispatch({
          type: "REQUEST_UPLOAD_XDATA_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        console.log("err", err)
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500 || err.response.status === 0){
          dispatch({
            type: "REQUEST_UPLOAD_XDATA_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_UPLOAD_XDATA_FAILED",
          payload: err.response.data.error[0]
        })
      })
  }
}

export const downloadSnapshot = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DOWNLOAD_SNAPSHOT"
    })
    const config = {
      headers: {     
        "Accept": 'application/json',         
        "Authorization": ''+authorizationAccessToken+''
      },
    }  
    axios
      .get(api_settings.url_base + api_endpoints.xdataDownload + payload.code + '/' + payload.major + '/' + payload.minor + '/' + payload.version_support_encryption,  config)
      .then(response => {
        dispatch({
          type: "REQUEST_DOWNLOAD_SNAPSHOT_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DOWNLOAD_SNAPSHOT_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DOWNLOAD_SNAPSHOT_FAILED",
          payload: err.response.data
        })
      })
  }
}

export const downloadLogs = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DOWNLOAD_XDATA_LOGS"
    })
    const config = {
      headers: {  
        "Accept": 'application/json',      
        "Authorization": ''+authorizationAccessToken+''
      },
    }  
    axios
      .get(api_settings.url_base + api_endpoints.xdataDownloadLogs,  config)
      .then(response => {
        dispatch({
          type: "REQUEST_DOWNLOAD_XDATA_LOGS_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DOWNLOAD_XDATA_LOGS_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DOWNLOAD_XDATA_LOGS_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const deleteBucket = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DELETE_BUCKET"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }  
    axios
      .delete(api_settings.url_base + api_endpoints.xdataBuckets + payload.code + "/",  config)
      .then(response => {
        dispatch({
          type: "REQUEST_DELETE_BUCKET_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DELETE_BUCKET_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DELETE_BUCKET_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const mandatoryUpdate = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_MANDATORY_UPDATE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }  
    axios
      .patch(api_settings.url_base + api_endpoints.mandatory_update + payload.code + "/", {
        mandatory_update: payload.mandatory_update
      },  config)
      .then(response => {
        dispatch({
          type: "REQUEST_MANDATORY_UPDATE_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_MANDATORY_UPDATE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const twoFaUpdate = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_2FA_UPDATE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }  
    axios
      .patch(api_settings.url_base + api_endpoints.two_FA_update + payload.code + "/", {
        otp_security_update: payload.otp_security_update
      },  config)
      .then(response => {
        dispatch({
          type: "REQUEST_2FA_UPDATE_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        dispatch({
          type: "REQUEST_2FA_UPDATE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const deleteDbVersion = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DATABASE_VERSION_DELETE"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.xdataVersionDelete + payload.code + '/' + payload.major + '/' + payload.minor, config)
      .then(response => {
        dispatch({
          type: "REQUEST_DATABASE_VERSION_DELETE_SUCCESS",
          payload: response
        });
        // 
      })
      .catch(err => { 
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DATABASE_VERSION_DELETE_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DATABASE_VERSION_DELETE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}