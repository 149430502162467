import axios from "axios";
import { api_settings, api_endpoints } from "../../services/api";
import { store } from "../../storeConfig/store";
import {logoutRedirect} from '../logoutRedirect/logoutRedirectAction'


export const getAllServiceEngineers = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_SERVICE_ENGINEER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .get(api_settings.url_base + api_endpoints.serviceEngineers +  '?offset=' + payload.offset, config)
      .then(response => {
        dispatch({
          type: "REQUEST_SERVICE_ENGINEER_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => {
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        else{
          dispatch({
            type: "REQUEST_SERVICE_ENGINEER_FAILED",
            payload: err.response.status
          })
        }
        
      })
  }
}

export const downloadsampleServiceEngineerTemplate = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE"
    })
    const config = {
      headers: {  
        "Accept": 'application/json',      
        "Authorization": ''+authorizationAccessToken+''
      },
    }  
    axios
      .get(api_settings.url_base + api_endpoints.sampleServiceEngineerTemplate,  config)
      .then(response => {
        dispatch({
          type: "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => {
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_FAILED",
          payload: err.response.data.error
        })
      })
  }
}

export const createServiceEngineers = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "CREATE_REQUEST_SERVICE_ENGINEER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .post(api_settings.url_base + api_endpoints.serviceEngineers, {
        "firstname": payload.firstname,
        "lastname": payload.lastname,
        "country": payload.country,
        "ext": payload.ext,
        "phone": payload.phone,
        "email": payload.email,
        "company_name": payload.company_name,
        "city": payload.city,
        "address": payload.address
      }, config)
      .then(response => {
        dispatch({
          type: "CREATE_REQUEST_SERVICE_ENGINEER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
          if(err.response.status === 401){
            logoutRedirect(dispatch)
          }    
          if(err.response.status === 500){
            dispatch({
              type: "CREATE_REQUEST_SERVICE_ENGINEER_FAILED_500",
              payload: true
            })
          }     
          dispatch({
            type: "CREATE_REQUEST_SERVICE_ENGINEER_FAILED",
            payload: err.response.data
          })
      })
  }
}

export const uploadServiceEngineers = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "REQUEST_UPLOAD_SERVICE_ENGINEER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Content-Type":'multipart/form-data',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    var bodyFormData = new FormData();
    bodyFormData.append('dataFile', payload.dataFile);
  
    axios
      .post(api_settings.url_base + api_endpoints.serviceEngineersUpload,  bodyFormData, config)
      .then(response => {
        dispatch({
          type: "REQUEST_UPLOAD_SERVICE_ENGINEER_SUCCESS",
          payload: response.data
        });
        // 
      })
      .catch(err => {
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500 || err.response.status === 0){
          dispatch({
            type: "REQUEST_UPLOAD_SERVICE_ENGINEER_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "REQUEST_UPLOAD_SERVICE_ENGINEER_FAILED",
          payload: err.response.data.error[0]
        })
      })
  }
}

export const editServiceEngineers = (payload) => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "UPDATE_REQUEST_SERVICE_ENGINEER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .put(api_settings.url_base + api_endpoints.serviceEngineers + payload.editid + "/update/", {       
        "firstname": payload.editfirstname,
        "lastname": payload.editlastname,
        "country": payload.editcountry,
        "ext": payload.editext,
        "phone": payload.editphone,
        "email": payload.editemail,
        "company_name": payload.editcompany_name,
        "city": payload.editcity,
        "address": payload.editaddress
      }, config)
      .then(response => {
        dispatch({
          type: "UPDATE_REQUEST_SERVICE_ENGINEER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        } 
        if(err.response.status === 500){
          dispatch({
            type: "UPDATE_REQUEST_SERVICE_ENGINEER_FAILED_500",
            payload: true
          })
        }
          dispatch({
            type: "UPDATE_REQUEST_SERVICE_ENGINEER_FAILED",
            payload: err.response.data.error
          })
        
      })
  }
}

export const deleteServiceEngineers = payload => {    
  return dispatch => {
    const { user } = store.getState().auth.login;
    const { impersonateUserInfo } = store.getState().impersonate.impersonate;
    let authorizationAccessToken = user.access;
    if(impersonateUserInfo?.status === "success"){
      let impersonateUserInfoData = impersonateUserInfo?.data;
      if(impersonateUserInfoData?.user_access_token){
        authorizationAccessToken = impersonateUserInfoData.user_access_token;
      }     
    }
    dispatch({
      type: "DELETE_REQUEST_SERVICE_ENGINEER"
    })
    const config = {
      headers: {        
        "Accept": 'application/json',
        "Authorization": ''+authorizationAccessToken+''
      },
    }
    axios
      .delete(api_settings.url_base + api_endpoints.serviceEngineers + payload.id + "/delete/", config)
      .then(response => {
        dispatch({
          type: "DELETE_REQUEST_SERVICE_ENGINEER_SUCCESS",
          payload: response.data
        });
      })
      .catch(err => {
        if(err.response.status === 401){
          logoutRedirect(dispatch)
        }
        if(err.response.status === 500){
          dispatch({
            type: "DELETE_REQUEST_SERVICE_ENGINEER_FAILED_500",
            payload: true
          })
        }
        dispatch({
          type: "DELETE_REQUEST_SERVICE_ENGINEER_FAILED",
          payload: err.response.data.error
        })
      })
  }
}



