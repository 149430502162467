const initalState = {
    loading: false,
    buckets: null,
    error:null,
    createError:null,
    createSuccess: null,
    bucketDataError:null,
    bucketDataSuccess:null,
    uploadLoading: false,
    uploadError: null,
    uploadSuccess: false,
    downloadLoading: false,
    downloadSuccess: null,
    downloadError: null,
    downloadLogsLoading:false,
    downloadLogsSuccess: null,
    downloadLogsError: null,
    deleteBucketLoading: false,
    deleteBucketSuccess: false,
    deleteBucketError: false,
    mandatoryUpdateLoading: false, 
    mandatoryUpdateSuccess:null,
    mandatoryUpdateError: null,
    upload500Error: null,
    create500Error: null,
    download500Error: null,
    deleteBucketError500: null,
    downloadLogs500Error: null,

    deleteDbVersionLoader: false,
    deleteDbVersionError: null,
    deleteDbVersionError500: null,
    deleteDbVersionDetails: null,

    updateLoading: false,
    updateSuccess: null,
    updateError: null,
    update500Error: null,

    twoWayUpdateLoading: false, 
    twoWayUpdateSuccess:null,
    twoWayUpdateError: null,
  }
  
  export const buckets = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_ALL_BUCKETS": {
        return { ...state, loading: true, error: null, createError:null, createSuccess: null, deleteBucketError:null, deleteBucketSuccess: false, downloadLogsSuccess: null, uploadSuccess:false }
      }
      case "REQUEST_ALL_BUCKETS_SUCCESS": {
        return { ...state, buckets: action.payload, error: null, loading: false, }
      }
      case "REQUEST_ALL_BUCKETS_FAILED": {
        return { ...state, loading: false, error: action.payload }
      }
      case "REQUEST_CREATE_BUCKET": {
        return { ...state, loading: true, createError: null }
      }
      case "REQUEST_CREATE_BUCKET_SUCCESS": {
        return { ...state, loading: false, createSuccess: action.payload, createError: null }
      }
      case "REQUEST_CREATE_BUCKET_FAILED": {
        return { ...state, loading: false, createError: action.payload }
      }
      case "REQUEST_CREATE_BUCKET_FAILED_500": {
        return { ...state, loading: false, create500Error: action.payload }
      }

      case "REQUEST_UPDATE_BUCKET": {
        return { ...state, updateLoading: true, updateError: null }
      }
      case "REQUEST_UPDATE_BUCKET_SUCCESS": {
        return { ...state, updateLoading: false, updateSuccess: action.payload, updateError: null }
      }
      case "REQUEST_UPDATE_BUCKET_FAILED": {
        return { ...state, updateLoading: false, updateError: action.payload }
      }
      case "REQUEST_UPDATE_BUCKET_FAILED_500": {
        return { ...state, updateLoading: false, update500Error: action.payload }
      }

      case "REQUEST_DATABASE_VERSION_DELETE": {
        return { ...state, deleteDbVersionLoader: true, deleteDbVersionError: null }
      }
      case "REQUEST_DATABASE_VERSION_DELETE_SUCCESS": {
        return { ...state, deleteDbVersionDetails: action.payload, deleteDbVersionError: null, deleteDbVersionLoader: false, }
      }
      case "REQUEST_DATABASE_VERSION_DELETE_FAILED": {
        return { ...state, deleteDbVersionLoader: false, deleteDbVersionError: action.payload }
      }
      case "REQUEST_DATABASE_VERSION_DELETE_FAILED_500": {
        return { ...state, deleteDbVersionLoader: false, deleteDbVersionError500: action.payload }
      }

      case "REQUEST_BUCKET_DATA": {
        return { ...state, loading: true, bucketDataError: null }
      }
      case "REQUEST_BUCKET_DATA_SUCCESS": {
        return { ...state, loading: false, bucketDataSuccess: action.payload, bucketDataError: null }
      }
      case "REQUEST_BUCKET_DATA_FAILED": {
        return { ...state, loading: false, bucketDataError: action.payload }
      }
      case "REQUEST_UPLOAD_XDATA": {
        return { ...state, uploadLoading: true, uploadSuccess:false, uploadError: null }
      }
      case "REQUEST_UPLOAD_XDATA_SUCCESS": {
        return { ...state, uploadLoading: false, uploadSuccess: true, uploadError: null }
      }
      case "REQUEST_UPLOAD_XDATA_FAILED": {
        return { ...state, uploadLoading: false, uploadSuccess:false, uploadError: action.payload }
      }
      case "REQUEST_UPLOAD_XDATA_FAILED_500": {
        return { ...state, uploadLoading: false, uploadSuccess:false, upload500Error: true }
      }
      case "REQUEST_DOWNLOAD_SNAPSHOT": {
        return { ...state, downloadLoading: true, downloadSuccess:null, downloadError: null }
      }
      case "REQUEST_DOWNLOAD_SNAPSHOT_SUCCESS": {
        return { ...state, downloadLoading: false, downloadSuccess: action.payload, downloadError: null }
      }
      case "REQUEST_DOWNLOAD_SNAPSHOT_FAILED": {
        return { ...state, downloadLoading: false, downloadSuccess:null, downloadError: action.payload }
      }
      case "REQUEST_DOWNLOAD_SNAPSHOT_FAILED_500": {
        return { ...state, downloadLoading: false, downloadSuccess:null, download500Error: action.payload }
      }
      case "REQUEST_DOWNLOAD_XDATA_LOGS": {
        return { ...state, downloadLogsLoading: true, downloadLogsSuccess:null, downloadLogsError: null }
      }
      case "REQUEST_DOWNLOAD_XDATA_LOGS_SUCCESS": {
        return { ...state, downloadLogsLoading: false, downloadLogsSuccess: action.payload, downloadLogsError: null }
      }
      case "REQUEST_DOWNLOAD_XDATA_LOGS_FAILED": {
        return { ...state, downloadLogsLoading: false, downloadLogsSuccess:null, downloadLogsError: action.payload }
      }
      case "REQUEST_DOWNLOAD_XDATA_LOGS_FAILED_500": {
        return { ...state, downloadLogsLoading: false, downloadLogsSuccess:null, downloadLogs500Error: action.payload }
      }
      case "REQUEST_DELETE_BUCKET": {
        return { ...state, deleteBucketLoading: true, deleteBucketSuccess:false, deleteBucketError: null }
      }
      case "REQUEST_DELETE_BUCKET_SUCCESS": {
        return { ...state, deleteBucketLoading: false, deleteBucketSuccess: true, deleteBucketError: null }
      }
      case "REQUEST_DELETE_BUCKET_FAILED": {
        return { ...state, deleteBucketLoading: false, deleteBucketSuccess:false, deleteBucketError: action.payload }
      }
      case "REQUEST_DELETE_BUCKET_FAILED_500": {
        return { ...state, deleteBucketLoading: false, deleteBucketSuccess:false, deleteBucketError500: action.payload }
      }
      case "REQUEST_MANDATORY_UPDATE": {
        return { ...state, mandatoryUpdateLoading: true, mandatoryUpdateSuccess:null, mandatoryUpdateError: null }
      }
      case "REQUEST_MANDATORY_UPDATE_SUCCESS": {
        return { ...state, mandatoryUpdateLoading: false, mandatoryUpdateSuccess:action.payload, mandatoryUpdateError: null }
      }
      case "REQUEST_MANDATORY_UPDATE_FAILED": {
        return { ...state, mandatoryUpdateLoading: false, mandatoryUpdateSuccess:null, mandatoryUpdateError: action.payload }
      }
      case "REQUEST_2FA_UPDATE": {
        return { ...state, twoWayUpdateLoading: true, twoWayUpdateSuccess:null, twoWayUpdateError: null }
      }
      case "REQUEST_2FA_UPDATE_SUCCESS": {
        return { ...state, twoWayUpdateLoading: false, twoWayUpdateSuccess:action.payload, twoWayUpdateError: null }
      }
      case "REQUEST_2FA_UPDATE_FAILED": {
        return { ...state, twoWayUpdateLoading: false, twoWayUpdateSuccess:null, twoWayUpdateError: action.payload }
      }
      default: {
        return state
      }
    }
  }
