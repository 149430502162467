import React,{useState,useEffect} from 'react';
import { Grid } from "@mui/material";
import { styled } from '@mui/system';
import Switch from '@mui/material/Switch';
import { withStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from "react-redux";
import { mandatoryUpdate } from "../../redux/actions/xdata/bucketActions"
// add twoFaUpdate
import { twoFaUpdate } from "../../redux/actions/xdata/bucketActions"


const Container = styled('div')(({ theme,height }) => ({
    position:"relative",
    width:"100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 10px #00000029",
    borderRadius: 5,
    opacity: 1,
    display:"flex",
    minHeight:height,
    flexDirection:"column",
    "& .box-container":{
        padding:"20px 36px"
    },
    "& .text-color": {
        fontSize: 15,
        color: '#C8102E',
        marginTop: 20,
        [theme.breakpoints.up("xl")]:{
          fontSize: 19,
       },
       [theme.breakpoints.down("xl")]:{
        fontSize: 14,
     },
    },
    '& .box-title':{
        flex:1,
        color:"#36333D",
        fontFamily:"Roboto",
        fontWeight:'bold',
        fontSize:14,
        [theme.breakpoints.up("xl")]:{
          fontSize: 20,
       },
       [theme.breakpoints.down("xl")]:{
        fontSize: 15,
     },
    },
    [theme.breakpoints.down('xl')]: {
        "& .box-container":{
            padding:"15px 31px"
        },
    }
}))

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "white",
        border: "6px solid #fff"
      },
      "&.MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: "black"
      },
      
    },
    thumb: {
      width: 24,
      height: 24
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    );
  });
  


export default function DetailsHead({column1, column2, column3, column4, column5, column6}) { 
    const dispatch = useDispatch();
    const [switchChecked, setSwitchChecked] = useState(false);
    const [switchTwoWayChecked, setTwoWaySwitchChecked] = useState(false);
      const { mandatoryUpdateSuccess, twoWayUpdateSuccess } = useSelector((state) => state.xdata.buckets);
      
      useEffect(()=>{
        if(column3?.switchOn){
          setSwitchChecked(column3?.switchOn)
        }
        if(column6?.switchOn){
          setTwoWaySwitchChecked(column6?.switchOn)
        }
      },[column1?.value, column3?.value, column6?.value])

      // useEffect(()=>{
      //   console.log("switchChecked", switchChecked)
      // },[switchChecked])

      // Comment this code to stop the cache issue
      // useEffect(()=>{
      //   if(mandatoryUpdateSuccess?.status === "success"){
      //     console.log("mandatoryUpdateSuccess", mandatoryUpdateSuccess)
      //     setSwitchChecked(mandatoryUpdateSuccess.data.mandatory_update)
      //   }
      // },[mandatoryUpdateSuccess])

      // useEffect(()=>{
      //   if(twoWayUpdateSuccess?.status === "success"){
      //     setTwoWaySwitchChecked(twoWayUpdateSuccess.data.otp_security_update)
      //   }
      // },[twoWayUpdateSuccess])
    
      const handleChange = (event) => {
        if(event.target.name === "checkedB"){
          let payload = {
            code: column3.bucketCode,
            mandatory_update: event.target.checked
          }
          dispatch(mandatoryUpdate(payload))
        }
        setSwitchChecked(event.target.checked)
      };

      const handleChangetwoWay = (event) => {
        if(event.target.name === "checkedD"){
          let payload = {
            code: column6.bucketCode,
            otp_security_update: event.target.checked
          }
          dispatch(twoFaUpdate(payload))
        }
        setTwoWaySwitchChecked(event.target.checked)
      };

      
  
  return (
        <Container >
            <Grid className='box-container'  container direction="row">
                <Grid item xs={4}>
                    <h3 className='box-title'>{column1?.label}</h3> 
                    <p className="text-color">{column1?.value}</p>
                </Grid>
                <Grid item xs={4}>
                    <h3 className='box-title'>{column2?.label}</h3> 
                    <p className="text-color">{column2?.value}</p>
                </Grid>
                <Grid item xs={4}>
                    <h3 className='box-title'>{column3?.label}</h3> 
                    <p className="customSwitch" style={{marginTop: 2}}>                        
                        { column3?.switch ? <FormControlLabel
                                control={
                                <IOSSwitch
                                    checked={switchChecked}
                                    onChange={handleChange}
                                    name="checkedB"
                                />
                                }
                            /> : '' }
                    </p>
                </Grid>
                <Grid item xs={4} style={{marginTop: 10}}>
                    <h3 className='box-title'>{column4?.label}</h3> 
                    <p className="text-color">{column4?.value}</p>
                </Grid>
                <Grid item xs={4} style={{marginTop: 10}}>
                    <h3 className='box-title'>{column5?.label}</h3> 
                    <p className="text-color">{column5?.value}</p>
                </Grid>

                <Grid item xs={4}>
                    <h3 className='box-title'>{column6?.label}</h3> 
                    <p className="customSwitch" style={{marginTop: 2}}>                        
                        { column6?.switch ? <FormControlLabel
                                control={
                                <IOSSwitch
                                    checked={switchTwoWayChecked}
                                    onChange={handleChangetwoWay}
                                    name="checkedD"
                                />
                                }
                            /> : '' }
                    </p>
                </Grid>
            </Grid>
        </Container>
  )
}
