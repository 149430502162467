const initalState = {
    loadingServiceEngineer: false,
    serviceEngineers: null,
    error:null,
    createServiceEngineerLoading: false, 
    createServiceEngineerError: null, 
    createServiceEngineerError500: null, 
    createServiceEngineerSuccess: null,
    updateServiceEngineerLoading: false, 
    updateServiceEngineerError: null,
    updateServiceEngineerError500: null,  
    updateServiceEngineerSuccess: null,
    deleteServiceEngineerLoading: false,
    deleteServiceEngineerError: null,
    deleteServiceEngineerError500: null,
    deleteServiceEngineerSuccess: false,

    downloadServiceEngineerTeplateLoading:false,
    downloadServiceEngineerTeplateSuccess: null,
    downloadServiceEngineerTeplateError: null,
    downloadServiceEngineerTeplate500Error: null,

    uploadServiceEngineerLoading: false,
    uploadServiceEngineerError: null,
    uploadServiceEngineerSuccess: false,
    uploadServiceEngineerError500: null,
  }
  
  export const serviceEngineer = (state = initalState, action) => {
    switch (action.type) {
      case "REQUEST_SERVICE_ENGINEER": {
        return { ...state, loadingServiceEngineer: true, error: null, createServiceEngineerLoading: false, createServiceEngineerError: null, createServiceEngineerSuccess: null, updateServiceEngineerSuccess: null, updateServiceEngineerError: null, deleteServiceEngineerSuccess: false }
      }
      case "REQUEST_SERVICE_ENGINEER_SUCCESS": {
        return { ...state, serviceEngineers: action.payload, error: null, loadingServiceEngineer: false, }
      }
      case "REQUEST_SERVICE_ENGINEER_FAILED": {
        return { ...state, loadingServiceEngineer: false, error: action.payload }
      }
      case "CREATE_REQUEST_SERVICE_ENGINEER": {
        return { ...state, createServiceEngineerLoading: true, createServiceEngineerError: null, createServiceEngineerSuccess: null }
      }
      case "CREATE_REQUEST_SERVICE_ENGINEER_SUCCESS": {
        return { ...state, createServiceEngineerSuccess: action.payload, createServiceEngineerError: null, createServiceEngineerLoading: false, }
      }
      case "CREATE_REQUEST_SERVICE_ENGINEER_FAILED": {
        return { ...state, createServiceEngineerLoading: false, createServiceEngineerError: action.payload }
      }
      case "CREATE_REQUEST_SERVICE_ENGINEER_FAILED_500": {
        return { ...state, createServiceEngineerLoading: false, createServiceEngineerError500: action.payload }
      }
      case "UPDATE_REQUEST_SERVICE_ENGINEER": {
        return { ...state, updateServiceEngineerLoading: true, updateServiceEngineerError: null, updateServiceEngineerSuccess: null }
      }
      case "UPDATE_REQUEST_SERVICE_ENGINEER_SUCCESS": {
        return { ...state, updateServiceEngineerSuccess: action.payload, updateServiceEngineerError: null, updateServiceEngineerLoading: false, }
      }
      case "UPDATE_REQUEST_SERVICE_ENGINEER_FAILED": {
        return { ...state, updateServiceEngineerLoading: false, updateServiceEngineerError: action.payload }
      }
      case "UPDATE_REQUEST_SERVICE_ENGINEER_FAILED_500": {
        return { ...state, updatServiceEngineerLoading: false, updateServiceEngineerError500: action.payload }
      }
      case "DELETE_REQUEST_SERVICE_ENGINEER": {
        return { ...state, deleteServiceEngineerLoading: true, deleteServiceEngineerError: null, deleteServiceEngineerSuccess: false }
      }
      case "DELETE_REQUEST_SERVICE_ENGINEER_SUCCESS": {
        return { ...state, deleteServiceEngineerSuccess: true, deleteServiceEngineerError: null, deleteServiceEngineerLoading: false, }
      }
      case "DELETE_REQUEST_SERVICE_ENGINEER_FAILED": {
        return { ...state, deleteServiceEngineerLoading: false, deleteServiceEngineerError: action.payload, deleteServiceEngineerSuccess: false }
      }
      case "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE": {
        return { ...state, downloadServiceEngineerTeplateLoading: true, downloadServiceEngineerTeplateSuccess:null, downloadServiceEngineerTeplateError: null }
      }
      case "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_SUCCESS": {
        return { ...state, downloadServiceEngineerTeplateLoading: false, downloadServiceEngineerTeplateSuccess: action.payload, downloadServiceEngineerTeplateError: null }
      }
      case "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_FAILED": {
        return { ...state, downloadServiceEngineerTeplateLoading: false, downloadServiceEngineerTeplateSuccess:null, downloadServiceEngineerTeplateError: action.payload }
      }
      case "REQUEST_DOWNLOAD_SERVICE_ENGINEER_TEMPLATE_FAILED_500": {
        return { ...state, downloadServiceEngineerTeplateLoading: false, downloadServiceEngineerTeplateSuccess:null, downloadServiceEngineerTeplate500Error: action.payload }
      }

      case "REQUEST_UPLOAD_SERVICE_ENGINEER": {
        return { ...state, uploadServiceEngineerLoading: true, uploadServiceEngineerSuccess:false, uploadServiceEngineerError: null }
      }
      case "REQUEST_UPLOAD_SERVICE_ENGINEER_SUCCESS": {
        return { ...state, uploadServiceEngineerLoading: false, uploadServiceEngineerSuccess: action.payload, uploadServiceEngineerError: null }
      }
      case "REQUEST_UPLOAD_SERVICE_ENGINEER_FAILED": {
        return { ...state, uploadServiceEngineerLoading: false, uploadServiceEngineerSuccess:false, uploadServiceEngineerError: action.payload }
      }
      case "REQUEST_UPLOAD_SERVICE_ENGINEER_FAILED_500": {
        return { ...state, uploadServiceEngineerLoading: false, uploadServiceEngineerSuccess:false, uploadServiceEngineerError500: action.payload }
      }

      default: {
        return state
      }
    }
  }
  